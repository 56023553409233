$(document).ready ->
  $('.parcel_item_form').submit((event) ->
    event.preventDefault()
    submitOrderItemsForm(this)
  )

  $('#resolve_form').submit((event) ->
    resolve_action = $('[name="resolve_action"]:checked').val();
    resolve_comment = $('[name="resolve_comment"]').val();
    if resolve_action and resolve_comment
      return
    else
      $('#resolve_error').removeClass("d-none");
      event.preventDefault()
      $('#resolve_button').disabled = false;
  )

submitOrderItemsForm = (form) ->
  action = $(form).attr('action')
  method = $(form).attr('method')
  button = $(form).find(':submit')
  data = $(form).serializeArray();
  $.ajax({
    method: method,
    url: action,
    data: data,
    dataType: 'json'
  }).done((data) ->
    if $('#removeModal' + data.parcel_item.id).length
      $("[data-dismiss=modal]").trigger({ type: "click" });
    $('#parcel_item_' + data.parcel_item.id).replaceWith(data.html)
    $('#parcel_item_' + data.parcel_item.id + ' .parcel_item_form').submit((event) ->
      event.preventDefault()
      submitOrderItemsForm(this)
    )

  ).fail( ->
  ).always( ->
    $(button).removeAttr("disabled")
  );
